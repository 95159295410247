
.ml-15{
    margin-left:15px;
}

.ml-30{
    margin-left:30px;
}
.heroTable{
    margin:15px;
    tr,td,th{
        border: 1px solid black;
    }
    td,th{
        padding:5px 15px;
    }
}

.btn{
    width:120px;
    height:30px;
    margin-bottom: 10px;
    border-radius: 8px;
}

.form{
    input{
        margin: 10px;
        width:300px;
        height: 20px;
    }
}

.update-table{
    tr,td,th{
        border: 1px solid black;
        padding:8px 30px;
        min-width: 200px;
    }
    td:nth-of-type(1) {
    min-width:70px;
    text-align: center;
      }
      th:nth-of-type(1){
        min-width:70px;
        text-align: center;
      }
    button{
        width: 100px;
        height:25px;
        border-radius: 7px;
    }
}
